import logo from "./logo/HeaderLogo.png";
import logo2x from "./logo/HeaderLogo.png";
import brand1 from "./logo/brand-01.png";

import bannerO1 from "./logo/Group 66.png";
import avt1 from "./logo/usdt.png";
import icon1 from "./logo/bnl-logo.png";
import about1 from "./layouts/about-01.png";
import about2 from "./layouts/about-02.png";
import about3 from "./layouts/about-03.png";
import about4 from "./layouts/about-04.png";
import about5 from "./layouts/about-05.png";
import about6 from "./layouts/about-06.png";
import portfolio from "./layouts/portfolio.png";
import line from "./background/line.png";
import team1 from "./layouts/team-01.png";
import team2 from "./layouts/team-02.png";
import team3 from "./layouts/team-03.png";
import team4 from "./layouts/team-04.png";
import team5 from "./layouts/team-05.png";
import team6 from "./layouts/team-06.png";
import team7 from "./layouts/team-07.png";
import team8 from "./layouts/team-08.png";
import create from "./layouts/create.png";
import quote from "./icon/quote.png";
import avatar from "./layouts/avt-01.png";
import accept1 from "./icon/bnlTokenShadow.png";
import accept2 from "./icon/dollar.png";
import accept3 from "./icon/p2p.png";
import accept4 from "./icon/thetherUsdt.png";
import slider1 from "./mobile-screen/slider1.png";
import slider2 from "./mobile-screen/slider2.png";
import slider3 from "./mobile-screen/slider3.png";
import slider4 from "./mobile-screen/slider4.png";
import slider5 from "./mobile-screen/slider5.png";
import slider6 from "./mobile-screen/slider6.png";
import slider7 from "./mobile-screen/slider7.png";
import slider8 from "./mobile-screen/slider8.png";

import partner1 from "./partner/partner1.avif";
import partner2 from "./partner/partner2.avif";
import partner3 from "./partner/partner3.avif";
import partner4 from "./partner/partner4.avif";
import partner5 from "./partner/partner5.avif";
import partner6 from "./partner/partner6.avif";
import partner7 from "./partner/partner7.avif";
import partner8 from "./partner/partner8.avif";
import partner9 from "./partner/partner9.avif";

import starRating from "./icon/star-rating.png";
import whyBenylo from "./about-us/whyBenylo.png";
import whatIsBenylo from "./about-us/about1.png";
import benyloCoin from "./about-us/benyloCoin.png";
import robot2 from "./about-us/robot2.png";
import bep20 from "./about-us/bep20.avif";
import bnlTokenAbout from "./about-us/bnlTokenAbout.png";
import tokenomics1 from "./item/tokenomics1.png";
import tokenomics2 from "./item/tokenomics2.png";
import tokenomics3 from "./item/tokenomics3.png";
import tokenomics4 from "./item/tokenomics4.png";
import tokenomicspie from "./item/pie-chart.png";

import backgroundBg from "./background/background-bg.png";
import blueTick from "./logo/blueTick.png";
export const IMAGES = {
  logo,
  logo2x,
  bannerO1,
  avt1,
  icon1,
  about1,
  about2,
  about3,
  about4,
  about5,
  about6,
  portfolio,
  line,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  brand1,
  create,
  quote,
  avatar,
  accept1,
  accept2,
  accept3,
  accept4,
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider8,
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
  partner9,
  starRating,
  whyBenylo,
  whatIsBenylo,
  benyloCoin,
  robot2,
  bep20,
  bnlTokenAbout,
  tokenomics1,
  tokenomics2,
  tokenomics3,
  tokenomics4,
  tokenomicspie,
  backgroundBg,
  blueTick,
};
